import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, PageHeader, Table } from 'antd';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import PaginatedSearchHeader from 'app/components/commons/Range/PaginatedSearchHeader/PaginatedSearchHeader';
import Pagination from 'app/components/commons/Range/Pagination/Pagination';
import { CoverCellRoom, PublishedCell } from 'app/components/lists/cells';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { useGetRooms } from 'app/hooks/data/rooms/useGetRooms';
import { usePublishRoom } from 'app/hooks/data/rooms/usePublishRoom';
import { RoomListElement } from 'app/typings';
import { dateAndHour } from 'app/utils/dates';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import {
  extractBoolean,
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

import './Rooms.scss';

const { Content } = Layout;
const { Column } = Table;

const PAGE_SIZE = 50;

const formatIsPublished = (isPublishedFilter?: boolean[]) => {
  if (isPublishedFilter?.length === 1) {
    return isPublishedFilter[0];
  }

  return undefined;
};

type RoomsListQueryParam = {
  search?: string;
  pageNumber?: number;
  dateSorting?: SortOrder;
  isPublishedFilter?: boolean[];
};

const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
  isPublishedFilter: [true, false],
} as RoomsListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  isPublishedFilter: 'pu',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
  isPublishedFilter: parseArrayParam(extractBoolean),
};

export const Rooms = () => {
  const history = useHistory();
  const location = useLocation();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<RoomsListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debouncedQueryParams = useDebounce(queryParam, 500);

  const makePayload = useCallback(() => {
    const sortingColumn =
      debouncedQueryParams.dateSorting &&
      debouncedQueryParams.dateSorting !== 'none'
        ? 'date'
        : undefined;
    const sortingOrder =
      debouncedQueryParams.dateSorting &&
      debouncedQueryParams.dateSorting !== 'none'
        ? formatSortingForAPI(debouncedQueryParams.dateSorting)
        : undefined;

    return {
      search: debouncedQueryParams.search,
      sortingColumn,
      sortingOrder,
      offset: ((debouncedQueryParams.pageNumber || 1) - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      isPublished: formatIsPublished(debouncedQueryParams.isPublishedFilter),
    };
  }, [
    debouncedQueryParams.dateSorting,
    debouncedQueryParams.isPublishedFilter,
    debouncedQueryParams.pageNumber,
    debouncedQueryParams.search,
  ]);

  const {
    data: rooms,
    isFetching: loading,
    refetch: reloadRooms,
  } = useGetRooms(makePayload());

  const { mutateAsync: publishRoom } = usePublishRoom();

  const handlePageNumber = (pageNumber: number) =>
    updateQueryParam({ pageNumber });

  const handleSearch = (search: string) =>
    updateQueryParam({ pageNumber: 1, search });

  const dateSortingHandler = (sort: SortOrder) =>
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });

  const handlePublishedFilter = (filters: boolean[]) => {
    updateQueryParam({
      isPublishedFilter: filters,
      pageNumber: 1,
    });
  };

  const handleIsPublishedFilter = (statuses: boolean[]) => {
    if (!statuses.length) {
      handlePublishedFilter([true, false]);
    } else {
      handlePublishedFilter(statuses);
    }
  };

  const goToEditPage = (id: number) => {
    history.push({
      pathname: `/rooms/${id}/edit`,
      state: { pathname: location.pathname + location.search },
    });
  };

  const handleRowActions = ({ id }: RoomListElement) => ({
    onClick: () => goToEditPage(id),
  });

  const actionMenu = (room: RoomListElement) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => goToEditPage(room.id)}>
              Edit
            </Menu.Item>
            <Menu.Item
              key="publish"
              onClick={async () => {
                await publishRoom(room.id.toString());
                await reloadRooms();
              }}
            >
              {room.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  const newRoomButton = (
    <Button
      type="primary"
      size="large"
      onClick={() =>
        history.push({
          pathname: 'rooms/new',
          state: { pathname: location.pathname + location.search },
        })
      }
    >
      New
    </Button>
  );

  const isLastPage = !!rooms && rooms.length < PAGE_SIZE;

  const renderHeader = () => (
    <PaginatedSearchHeader
      onSearch={handleSearch}
      currentSearch={queryParam.search}
      currentPage={queryParam.pageNumber || 1}
      setPageNumber={handlePageNumber}
      isLastPage={isLastPage}
    />
  );

  return (
    <Layout className="page-list room-list">
      <PageHeader className="header" title="Rooms" extra={newRoomButton} />
      <Content className="body">
        <Table<RoomListElement>
          dataSource={rooms ?? []}
          pagination={false}
          title={renderHeader}
          loading={loading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={(__, ___, sorter) => {
            const { order } = Array.isArray(sorter) ? sorter[0] : sorter;

            dateSortingHandler(convertFromAntdSortOrder(order));
          }}
        >
          <Column
            title="Name"
            dataIndex="categoryName"
            render={CoverCellRoom}
          />
          <Column title="Hotel" dataIndex="hotelName" />
          <Column
            title="Last modified"
            dataIndex="updatedAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={dateAndHour}
          />
          <Column
            title={
              <ModalCheckBox
                title="Status"
                defaultValue={[true, false]}
                onChange={handleIsPublishedFilter}
                items={[
                  {
                    value: true,
                    label: 'Published',
                  },
                  {
                    value: false,
                    label: 'Unpublished',
                  },
                ]}
                value={queryParam.isPublishedFilter}
              />
            }
            dataIndex="published"
            render={PublishedCell}
            className="sort-column"
          />
          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        <Pagination
          className="footer"
          currentPage={queryParam.pageNumber || 1}
          setPageNumber={handlePageNumber}
          isLastPage={isLastPage}
        />
      </Content>
    </Layout>
  );
};

export default Rooms;
